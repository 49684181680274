.left-login,
.right-login {
   flex: 1;
   height: 100%;
}

.left-login {
   display: flex;
   background-color: #0036af;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.right-login {
   background-color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
}

.logo-login {
   margin-bottom: 20px;
   width: 143px;
}

.excl-login {
   color: #809bd7;
}

.login-form {
   margin-top: 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.input-login {
   height: 48px;
   width: 400px;
   margin-bottom: 25px;
   padding-left: 10px;
   border: 1px solid #bdbdbd;
   border-radius: 4px;
   font-size: 18px;
}

.input-login::placeholder {
   font-size: 15px;
}

.password-holder-login {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border: 1px solid #bdbdbd;
   border-radius: 4px;
   margin-bottom: 10px;
}

.input-login-password {
   height: 48px;
   width: 360px;
   padding-left: 10px;
   border: 0px solid #bdbdbd;
   border-radius: 4px;
   font-size: 18px;
}
.input-login-password::placeholder {
   font-size: 15px;
}
.input-login-password:focus {
   outline: none;
}
.input-login:focus {
   outline: none;
}

.toggle-visisbilty {
   color: balck;
   margin-right: 10px;
   cursor: pointer;
}

.remember-me {
   display: flex;
   align-items: center;
}

.additional-login-details {
   height: 48px;
   width: 400px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.checkbox-login {
   margin-right: 5px;
   margin-top: 4px;
   align-self: center;
   cursor: pointer;
}

.forget-password-login {
   color: #809bd7;
   cursor: pointer;
   text-decoration: none;
}

.login-submit-button {
   align-self: center;
   margin-top: 10px;
   height: 48px;
   width: 410px;
   border: 0px solid transparent;
   border-radius: 4px;
   background-color: #0036af;
}

.login-submit-button:hover {
   cursor: pointer;
   background-color: #0026aa;
}

.login-click-text {
   font-size: 14px;
   color: #fff;
   text-align: center;
   font-weight: 500;
   font-family: "Poppins", sans-serif;
}

.route-to-signup {
   font-family: "Roboto", sans-serif;
   color: #0036af;
   text-decoration: none;
}

.error-message {
   color: red;
   font-size: 11px;
   line-height: 12px;
   text-align: center;
   display: flex;
   justify-content: center;
}

/* .parent-container-login{
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-wrap: wrap;
} */

/* .text-container {
  display: flex;
  flex-direction: column;
} */

/* .main-heading {
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: white;
} */

/* .sub-heading {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: white;
} */

/* .welcome-message {
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
} */

/* .login-form-container {
  display: flex;
  flex-direction: column;
} */

/* .logo_and_texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
} */

/* .no-memeber {
  margin-top: 8px;
  margin-left: 5px;
  align-self: left;
} */

/* .welcome-message {
  margin-bottom: 25px;
  color: #212121;
} */

/* .detail-heading {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #616161;
} */
