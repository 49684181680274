.ce-dropdown {
   background-color: white;
   border: 1px solid #ccc;
   border-radius: 5px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
   z-index: 1000;
   min-width: 140px;
   display: block;
   overflow: hidden;
   width: 200px;
   position: absolute;
   top: 30px;
}

.ce-dropdown-list {
   list-style-type: none;
   margin: 0;
   padding: 0;
   max-height: 144px; /* Set a specific height */
   overflow-y: auto; /* Make it scrollable if content exceeds height */
}

.ce-dropdown-list::-webkit-scrollbar {
   width: 6px; /* Thinner scrollbar */
}

.ce-dropdown-list::-webkit-scrollbar-thumb {
   background-color: #cecece; /* Color of the scrollbar thumb */
   border-radius: 3px; /* Rounded corners */
}

.ce-dropdown-list::-webkit-scrollbar-thumb:hover {
   background-color: #c6c6c6; /* Color when hovered */
}

.ce-dropdown-item {
   padding: 8px 12px;
   cursor: pointer;
   color: black; /* Ensure text color is black */
}

.ce-dropdown-item:hover {
   background-color: #f0f0f0;
}

.ce-dropdown-close {
   background: none;
   border: none;
   font-size: 16px;
   cursor: pointer;
   position: absolute;
   top: 8px;
   right: 8px;
}
