.word-constructor-container {
   width: 100%;
   margin: 0 auto 150px auto;
   position: relative;
}
.word-constructor {
   width: 100%;
}

.word-constructor-disable{
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   position: absolute;
   z-index: 9999;
   cursor: not-allowed !important;
   opacity: 0.5;
   background-color: white;
}

.ce-close-icon {
   position: absolute;
   top: 13px;
   right: 10px;
   cursor: pointer;
   opacity: 0.5;
   transition: ease 0.3s;
}

.ce-close-icon:hover {
   opacity: 1;
   transition: ease 0.3s;
}

.main-constructor-container {
   min-height: 44px;
   width: 100%;
   /* margin-bottom: 10px; */
   border: 1px solid #cec7c7;
   border-radius: 4px;
   padding: 10px;
   box-sizing: border-box;
   display: flex;
   flex-wrap: wrap;
   font-size: 16px;
   gap: 4px;
}

/* Input field */
.new-word {
   font-size: 15px;
   width: auto;
   border: none;
   outline: none;
   box-sizing: border-box;
   display: inline-block;
   min-width: 60px;
   padding: 0 4px;
   border-radius: 4px;
}

.new-word::placeholder {
   color: #cec7c7; /* Lighter placeholder color */
   font-size: 15px;
   font-weight: 200;
}

/* Editable span styling */
.instruction-word-container {
   background-color: transparent;
   margin-right: 4px;
   cursor: text;
   display: inline-block;
   padding: 0 8px 2px 8px;
   outline: none;
   vertical-align: middle;
   line-height: 20px;
   font-size: 16px;
   min-height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-bottom: 1px solid #cec7c750;
   position: relative;
}

.instruction-word-container::before,
.instruction-word-container::after {
   content: "";
   position: absolute;
   bottom: 0px;
   width: 1px;
   height: 5px;
   background-color: #cec7c750;
}

.instruction-word-container::before {
   left: 0px;
}

.instruction-word-container::after {
   right: 0px;
}

.instruction-word-container:focus {
   border-bottom: 1px solid #007bff50;
}

.instruction-word-container:focus::before,
.instruction-word-container:focus::after {
   background-color: #007bff50;
}

/* Prevents any extra gaps when user interacts */
.instruction-word-container div {
   display: inline-block;
   line-height: inherit;
   vertical-align: middle;
}

.instruction-word-container-empty-variable,
.instruction-word-container-existing-variable {
   background-color: transparent;
   margin-right: 4px;
   cursor: text;
   display: inline-block;
   padding: 0 4px 2px 4px;
   outline: none;
   vertical-align: middle;
   line-height: 20px;
   font-size: 16px;
   min-height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-bottom: 1px solid #cec7c750;
   position: relative;
}

.instruction-word-container-empty-variable:focus,
.instruction-word-container-existing-variable:focus {
   border-bottom: 1px solid #e2950050;
}

.instruction-word-container-empty-variable::before,
.instruction-word-container-empty-variable::after,
.instruction-word-container-existing-variable::before,
.instruction-word-container-existing-variable::after {
   content: '"';
   top: 0px;
}

.instruction-word-container.instruction-word-container-empty-variable::before,
.instruction-word-container.instruction-word-container-empty-variable::after,
.instruction-word-container.instruction-word-container-existing-variable::before,
.instruction-word-container.instruction-word-container-existing-variable::after {
   content: none;
}

.instruction-word-container,
.instruction-word-container-empty-variable,
.instruction-word-container-existing-variable {
   /* border-radius: 6px; */
}
.instruction-word-container.highlight {
   background-color: #007bff15;
}
.instruction-word-container-empty-variable.highlight {
   background-color: #e2950015;
}
.instruction-word-container-existing-variable.highlight {
   background-color: #0b815a15;
}

.instruction-word-container-empty-variable {
   color: #e29500;
}

.instruction-word-container-existing-variable {
   color: #0b815a;
}

.instruction-word-container-existing-variable:focus {
   border-bottom: 1px solid #0b815a50;
}

/* -------------------------- */

/* 
.word-constructor {
  max-width: 500px;
  margin: 0 auto;
}

.main-constructor-container {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #cec7c7;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  gap: 4px;
  overflow-x: auto;
}

.main-constructor-container::-webkit-scrollbar {
  height: 6px;
}

.main-constructor-container::-webkit-scrollbar-thumb {
  background-color: #cec7c7;
  border-radius: 3px;
}

.main-constructor-container::-webkit-scrollbar-track {
  background: transparent;
}

.new-word {
  font-size: 15px;
  width: auto;
  border: none;
  outline: none;
  box-sizing: border-box;
  display: inline-block;
  min-width: 60px;
  padding: 0 4px;
  border-radius: 4px;
}

.new-word::placeholder {
  color: #cec7c7; 
  font-size: 15px;
}

.instruction-word-container {
  background-color: transparent;
  margin-right: 4px;
  cursor: text;
  display: inline-block;
  padding: 0 8px 2px 8px;
  outline: none;
  vertical-align: middle;
  line-height: 20px;
  font-size: 16px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cec7c750;
  position: relative;
}

.instruction-word-container::before,
.instruction-word-container::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 1px;
  height: 5px;
  background-color: #cec7c750;
}

.instruction-word-container::before {
  left: 0px;
}

.instruction-word-container::after {
  right: 0px;
}

.instruction-word-container:focus {
  border-bottom: 1px solid #007bff50;
}

.instruction-word-container:focus::before,
.instruction-word-container:focus::after {
  background-color: #007bff50;
}

.instruction-word-container div {
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
} */
