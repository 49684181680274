.profile-container {
   position: relative;
   display: inline-block;
}

.profile-icon {
   cursor: pointer;
}

.profile-dropdown-container {
   position: absolute;
   top: 110%;
   right: 20px;
   background-color: white;
   width: 240px;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   overflow: hidden;
   z-index: 1000;
   border: 1px solid #f5f5f5;
}

.profile-dropdown-item.special {
   color: #1d86ff;
}

.profile-dropdown-item.special img {
   filter: invert(44%) sepia(86%) saturate(4247%) hue-rotate(184deg)
      brightness(98%) contrast(102%);
}

/* .profile-dropdown-item.logout img{ */
/* filter: invert(100%) brightness(0%) contrast(100%); */
/* } */

.profile-dropdown-item {
   display: flex;
   align-items: center;
   gap: 8px;
   padding: 8px 16px;
   cursor: pointer;
   transition: background-color 0.2s ease;

   font-size: 14px;
}

.profile-dropdown-item img {
   width: 13px;
}

.profile-dropdown-item:hover {
   background-color: #f0f0f0;
}

.profile-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 12px 16px;
   box-sizing: border-box;
   border-bottom: 1px solid #f0f0f0;
}

.profile-header-detail h4 {
   color: black;
   font-size: 16px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.4px;
   text-align: left;
   margin: 0;
   margin-bottom: 5px;
}

.profile-header-detail p {
   color: #9e9e9e;

   font-family: "Roboto";
   font-size: 12px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.4px;
   text-align: left;

   margin: 0;
}

.profile-header-img {
   display: flex;
   align-items: center;
   justify-content: center;
}

.profile-main {
   border-bottom: 1px solid #f0f0f0;
}
