/* .top-nav-cont {
  position: sticky;
  top: 0;
  z-index: 1000;
} */

.nav {
   display: flex;
   justify-content: space-between;
   background-color: white;
   align-items: center;
   height: 64px;
}

.navtitle {
   font-size: 14px;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: 0.75px;
   color: #000000;
   padding-top: auto;
   margin-top: auto;
   margin-bottom: auto;
   /* padding: auto 0 auto 10px; */
   padding-left: 10px;
   text-transform: capitalize;
}

.top-nav-right {
   display: flex;
   align-items: center;
   height: 100%;
}

.top-nav-right-ce {
   display: flex;
   align-items: center;
   gap: 24px;
}
.top-nav-right-ce p {
   font-size: 10px;
   font-weight: 400;
   letter-spacing: 0.4px;
   text-align: left;
   margin: 0;
}

.top-nav-right-ce > div {
   width: 70px;
   height: 42px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.ac {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   border-left: 1px solid #f5f5f5;
   margin-left: 33px;
   position: relative;
}

.local-data-table-page-header {
   height: 40px;
   display: inline-flex;
   align-items: center;
   margin: 10px 0 0 20px;
}

.local-data-table-page-header > div {
   display: inline-flex;
   align-items: center;
   gap: 10px;
}

.local-data-table-page-header > p {
   margin: 0 10px;
}

.local-data-table-page-header p,
.local-data-table-page-header span {
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0.25px;
   text-align: left;
}

.local-data-table-page-header img {
   margin-right: 10px;
   cursor: pointer;
}

.edit-ld-filename-input {
   border: 1px solid #9e9e9e;
   outline: none;
   padding: 6px;
   border-radius: 4px;
   width: auto;
}
