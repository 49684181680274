@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.parent-reset {
   display: flex;
   flex-direction: row;
   height: 100vh;
}

.right-reset {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   flex: 1;
}

.left-reset {
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: #0036af;
}

.main-reset {
   font-size: 48px;
   align-content: center;
   color: white;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
}

.sub-reset {
   margin-top: 24px;
   font-size: 16px;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   color: white;
   text-align: center; /* Center-align the text */
}

.new-password-form {
   display: flex;
   flex-direction: column;
   padding-top: 20px; /* Add padding before the input fields */
}

.font-reset {
   margin-top: 10px;
   margin-bottom: 8px;
   font-family: "Roboto", sans-serif;
   font-weight: 400;
   font-size: 16px;
}

.input-password-new {
   width: 400px;
   height: 48px;
   border: 1px solid #bdbdbd;
   border-radius: 4px;
   font-size: 25px;
   margin: 3px 0px;
   caret-color: #0036af;
}

.logo-reset {
   margin-bottom: 30px;
}

.logo-reset img {
   width: 143px;
}

.reset-password-button {
   margin-top: 25px;
   width: 400px;
   height: 48px;
   color: white;
   background-color: #0036af;
   border: 0px solid transparent;
   border-radius: 4px;
   font-family: "Poppins", sans-serif;
   font-weight: 500;
   letter-spacing: 1.5px;
   cursor: pointer;
}

.input-password-new::-webkit-caret {
   border-width: 10px;
   border-style: solid;
   border-color: red;
}

/* .password-reset {
  display: flex;
  flex-direction: column;
} */

/* .input-password-new {
  caret-color: #0036af;
} */
