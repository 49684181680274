.card-row {
   flex-direction: row !important;
   justify-content: space-between;
   /* gap: 40px; */
}

.card-content {
   display: flex;
   align-items: flex-start;
}

.card-title {
   border: none;
   font-size: 16px;
   color: #9e9e9e;
   align-items: center;
   font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
}

.rectangle {
   width: 6px;
   height: 81px;
   flex-shrink: 0;
   border-radius: 4px;
   background: #2654bb;
}

.row.recent,
.row.threads {
   background-color: white;
   box-sizing: border-box;
   padding: 28px 20px;
}

/* .right-side {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
} */
/* .dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 28px 56px;
} */

/* .row {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

/* .card-row {
  flex-direction: row;
} */

/* .card-content {
  display: flex;
  align-items: flex-start;
} */

/* .card-title {
  border: none;
  font-size: 16px;
  color: #9e9e9e;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

/* .rectangle {
  width: 6px;
  height: 81px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #2654bb;
} */

/* .column-title {
  text-align: center;
  color: #667085;
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
} */

/* .table .ant-table-thead > tr > th {
  background-color: #ffffff;
  max-height: 44px;
  padding: 0;
} */
