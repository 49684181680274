@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.changed-password .main-reset {
   text-align: center;
}

.changed-password .sub-reset {
   justify-content: center;
}

.changed-logo-reset {
   display: flex;
   flex-direction: column;
   margin-bottom: 30px;
   justify-content: center;
   align-items: center;
}

.botgauge-logo-changed-password {
   margin-bottom: 40px;
   width: 143px;
}

.done-logo-changed-password {
   height: 72px;
   width: 72px;
}

.changed-password-info {
   margin-top: 20px;
   font-family: Roboto;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0.5px;
   text-align: left;
}

.go-back-successfull-password-changed {
   margin-top: 20px;
   width: 400px;
   border: 1px solid transparent;
   border-radius: 4px;
   background-color: #0036af;
   height: 48px;
   letter-spacing: 1.5px;
   cursor: pointer;
}

.change-reset-success-back-to-login {
   text-decoration: none;
   color: white;
}

/* .changed-parent-reset {
    display: flex;
    flex-direction: row;
    height: 100vh;
} */

/* .changed-right-reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
} */

/* .changed-left-reset {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0036af;
} */

/* 
.changed-reset-password-button {
  margin-top: 25px;
  width: 400px;
  height: 48px;
  color: white;
  background-color: #0036af;
  border: 0px solid transparent;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 1.5px;
} */

/* .changed-new-password-form {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
} */

/* .changed-password-reset {
  display: flex;
  flex-direction: column;
} */

/* .changed-font-reset {
  margin-top: 10px;
  margin-bottom: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
} */

/* .changed-input-password-new {
  width: 400px;
  height: 48px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  font-size: 25px;
  margin: 3px 0px;
} */

/* font-size: 48px;
  align-content: center;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400; */

/* margin-top: 24px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: white;
  text-align: center; */
