:root {
   --blue: rgba(29, 134, 255, 1);
   --caption-font-family: "Roboto", Helvetica;
   --caption-font-size: 12px;
   --caption-font-style: normal;
   --caption-font-weight: 400;
   --caption-letter-spacing: 0.4000000059604645px;
   --caption-line-height: 16px;
   --collection-1-showglobaleditor: initial;
   --comp: rgba(255, 236, 218, 0.5);
   --compilation-text: rgba(255, 122, 0, 1);
   --condition: rgba(29, 134, 255, 0.7);
   --error-100: rgba(254, 226, 226, 1);
   --error-300: rgba(252, 165, 165, 1);
   --error-500: rgba(239, 68, 68, 1);
   --error-700: rgba(185, 28, 28, 1);
   --gray-300: rgba(224, 224, 224, 1);
   --gray-800: rgba(66, 66, 66, 1);
   --loop: rgba(255, 153, 0, 0.6);
   --mild: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.15);
   --primary-100: rgba(179, 195, 231, 1);
   --primary-200: rgba(128, 155, 215, 1);
   --primary-300: rgba(128, 155, 215, 1);
   --primary-400: rgba(38, 84, 187, 1);
   --primary-50: rgba(224, 231, 245, 1);
   --primary-500: rgba(0, 54, 175, 1);
   --primary-600: rgba(0, 48, 168, 1);
   --primary-700: rgba(0, 41, 159, 1);
   --primary-800: rgba(0, 34, 150, 1);
   --primary-900: rgba(0, 22, 134, 1);
   --secondary: rgba(175, 121, 0, 1);
   --selection: 0px 0px 4px 0px rgba(0, 148, 255, 0.57);
   --shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
   --shiftxcomblack: rgba(0, 0, 0, 1);
   --shiftxcomblack-12: rgba(0, 0, 0, 0.12);
   --shiftxcomblack-60: rgba(0, 0, 0, 0.6);
   --shiftxcomblack-8: rgba(0, 0, 0, 0.08);
   --shiftxcomblack-87: rgba(0, 0, 0, 0.87);
   --shiftxcomdeep-fir: rgba(0, 0, 255, 1);
   --shiftxcommystic: rgba(234, 239, 242, 1);
   --shiftxcomnero: rgba(255, 255, 255, 1);
   --shiftxcompickled-bluewood: rgba(45, 65, 86, 1);
   --shiftxcomroyal-blue: rgba(66, 66, 240, 1);
   --success-100: rgba(209, 250, 229, 1);
   --success-300: rgba(110, 231, 183, 1);
   --success-50: rgba(236, 253, 245, 1);
   --success-500: rgba(16, 185, 129, 1);
   --success-700: rgba(4, 120, 87, 1);
   --success-900: rgba(6, 78, 59, 1);
   --warning-100: rgba(254, 243, 199, 1);
   --warning-300: rgba(252, 211, 77, 1);
   --warning-500: rgba(245, 158, 11, 1);
   --warning-700: rgba(180, 83, 9, 1);
   --white: rgba(255, 255, 255, 1);
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-collection-1-mode="mode-1">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-collection-1-mode="mode-1"] {
   --collection-1-showglobaleditor: initial;
}

[data-collection-1-mode="mode-2"] {
   --collection-1-showglobaleditor: none;
}
