@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.parent-signup-container {
   display: flex;
   flex-direction: row;
   height: 100vh;
}

.left-div {
   display: flex;
   justify-content: center;
   align-items: center;
   flex: 1;
   background-color: #0036af;
   color: white;
   font-family: "Roboto";
}

.right-div {
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: center;
}

.big-tagline {
   font-family: Roboto;
   font-size: 48px;
   font-weight: 400;
   line-height: 56px;
   text-align: left;
   text-align: center;
}

.smaller-tagline {
   font-family: Roboto;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0.15000000596046448px;
   text-align: center;
}

.register-form {
   display: flex;
   flex-direction: column;
   gap: 2rem;
}

.logo-container {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   align-items: center;
   justify-content: center;
}

.welcome-text {
   font-family: Roboto;
   font-size: 20px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0.15000000596046448px;
   text-align: center;
}

.span-exc {
   font-family: Roboto;
   font-size: 20px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0.15000000596046448px;
   text-align: center;
   color: #0036af;
}

.register-form .input-form {
   display: flex;
   flex-direction: column;
   gap: 1.2rem;
}

.input-a {
   display: flex;
   flex-direction: column;
   justify-content: left;
   gap: 10px;
}

.input-a .desc {
   font-family: Roboto;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0.15000000596046448px;
   text-align: left;
}

.actual-input {
   box-sizing: border-box;
   padding: 0px 5px;
   width: 400px;
   height: 50px;
   border-radius: 4px;
   border: 1px solid #9e9e9e;
   display: flex;
   align-items: center;
}

.input-cont-val {
   width: 400px;
   height: 40px;
   outline: none;
   border: 0;
   font-size: 1.1rem;
}

.input-cont-val:hover {
   outline: none;
}

.img-in {
   margin: 0px 4px;
}

.input-cont-val::placeholder {
   font-size: small;
}

.input-cont-val {
   width: 100%;
   padding: 8px;
   border: 0px solid #ccc;
   background-color: transparent;
   border-radius: 4px;
   box-sizing: border-box;
   font-size: 16px;
   color: #9e9e9e;
}

.input-cont-val option {
   font-size: 0.9rem;
   background-color: #fff;
   color: #333;
   border-radius: 4px;
   border: 1px solid transparent;
}

.register-button {
   outline: none;
   width: 400px;
   font-family: "Roboto";
   height: 56px;
   gap: 0px;
   border: 1px solid transparent;
   border-radius: 4px;
   opacity: 0px;
   background: #0036af;
   color: white;
   font-size: 14px;
   font-weight: 500;
   line-height: 16px;
   letter-spacing: 1.25px;
   text-align: center;
}

/* .text-container-signup {
  display: flex;
  flex-direction: column;
} */
