.parent-cont-os {
   display: flex;
   align-items: center;
}

.toggle-button {
   display: flex;
   flex-direction: column; /* Change the flex direction to column */
   align-items: center; /* Align items horizontally */
   justify-content: center; /* Center items vertically */
}

.toggle-button input[type="checkbox"] {
   width: 1rem;
   height: 1rem;
   margin-left: 1.2rem;
   margin-bottom: 0.5rem;
}

.text-select {
   font-family: Roboto;
   font-size: 14px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.4000000059604645px;
   text-align: center;
   color: #424242;
   align-self: center;
}
